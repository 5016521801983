export const storeSet = (key, value) => {
  if (key)
    localStorage.setItem(
      key,
      typeof value === "object" ? JSON.stringify(value) : value
    );
};

export const storeGet = (key) => {
  if (key) {
    let oData = localStorage.getItem(key);
    let data;
    try {
      data = JSON.parse(oData);
    } catch (ex) {
      data = oData;
    }
    return data;
  }
};
