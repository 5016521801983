import axios from "axios";
import { storeSet } from "./storage";

const LOGIN_URL = "/login";
const REGISTER_URL = "/register";
const FORGOT_URL = "/password/email";
const UPDATE_URL = "/edit";
const DETAILS_URL = "/details";
const RESET_URL = "/password/reset";

export const login = (params) => {
  return axios.post(LOGIN_URL, params).then((response) => {
    storeSet("user", response.data.success);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.success.token}`;
    return response;
  });
};

export const register = (params) => {
  return axios.post(REGISTER_URL, params).then((response) => {
    //storeSet("user", response.data.success);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.success.token}`;
    return response;
  });
};

export const forgot = (email) => {
  return axios.post(FORGOT_URL, { email }).then((response) => {
    return response;
  });
};

export const update = (params) => {
  return axios.patch(UPDATE_URL, params).then((response) => {
    return response;
  });
};

export const reset = (params) => {
  return axios.post(RESET_URL, params).then((response) => {
    return response;
  });
};

export const details = (params) => {
  return axios.post(DETAILS_URL, params).then((response) => {
    return response;
  });
};

export const verifyEmail = (params) => {
  return axios.get(window.location.hash.replace("#", "")).then((response) => {
    return response;
  });
};
